<template>
  <b-container fluid>
    <div
      v-if="corporationsLoading"
      class="d-flex justify-content-center mb-3 mt-5"
    >
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
        type="grow"
        variant="primary"
      ></b-spinner>
      <span class="h4 mt-2 text-primary"> {{ $t("app.loading") }}... </span>
    </div>
    <b-row v-else-if="corporation">
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <div class="d-flex align-items-center">
              <div
                class="wrapper-menu"
                @click="
                  $router.push({
                    name: 'admin.corporations',
                  })
                "
              >
                <div class="main-circle">
                  <i class="ri-arrow-left-s-line"></i>
                </div>
              </div>
              <span class="h4 mt-2 ml-2">{{ corporation.name }}</span>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="3">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("corporations.details") }}</h4>
          </template>
          <template v-slot:body>
            <div>
              <strong
                ><i class="ri-global-line"></i> {{ $t("corporations.name") }}:
              </strong>
              {{ corporation.name }}
            </div>
            <div>
              <span>
                <strong
                  ><i class="ri-global-line"></i>
                  {{ $t("corporations.business-name") }}:
                </strong>
                {{ corporation.business_name }} </span
              ><br />
              <span>
                <strong
                  ><i class="ri-global-line"></i> {{ $t("corporations.rut") }}:
                </strong>
                {{ corporation.rut }}
              </span>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="9">
        <b-row>
          <b-col md="12">
            <list-colleges></list-colleges>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ListColleges from "../Colleges";
export default {
  components: {
    ListColleges,
  },
  mounted() {
    this.fetchCorporation(this.$route.params.id);
  },
  data() {
    return {
      isBusy: false,
    };
  },
  methods: {
    ...mapActions({
      fetchCorporation: "fetchCorporation",
    }),
  },
  computed: {
    ...mapGetters({
      corporation: "corporation",
      corporationsLoading: "corporationsLoading",
    }),
  },
};
</script>
