<template>
  <b-modal id="modal-add-college" hide-footer>
    <template #modal-header>
      <h4>
        {{ $t("corporations.add-college") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row align-h="center">
            <b-col md="12" sm="12">
              <b-row align-v="center">
                <!-- User -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('user-college.name')"
                  label-for="college_id"
                >
                  <ValidationProvider
                    :name="$t('user-college.name')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="data.college_id"
                      :placeholder="$t('user-college.name')"
                      :options="colleges"
                      label="name"
                      :reduce="(item) => item.id"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                      <template #no-options="{}">
                        {{ $t("app.not-found") }}...
                      </template>
                    </v-select>
                    <div v-if="errors[0]">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>
          <hr />
          <b-button
            class="float-right"
            :disabled="corporationsLoading"
            variant="success"
            type="submit"
          >
            <span v-if="corporationsLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            class="float-right mr-1"
            @click="$bvModal.hide('modal-add-college')"
            variant="outline-danger"
            type="reset"
          >
            {{ $t("app.cancel") }}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { core } from "@/config/pluginInit";

export default {
  name: "AddUser",
  mounted() {
    this.getData();
  },
  data() {
    return {
      data: {
        college_id: null,
      },
    };
  },
  methods: {
    ...mapActions({
      saveForm: "addCollegeToCorporation",
      fetchColleges: "fetchColleges",
    }),
    async getData() {
      await this.fetchColleges({
        paginate: false,
        dontHaveCorporation: true,
      });
    },
    async show() {
      this.reserForm();
      this.$bvModal.show("modal-add-college");
    },
    async onSubmit() {
      const resp = await this.saveForm({
        ...this.data,
        corporation_id: this.$route.params.id,
      });
      this.$bvModal.hide("modal-add-college");
      this.$emit("refresh");
      if (resp?.status === 201 || resp?.status === 200) {
        core.showSnackbar(
          "success",
          this.$t("corporations.college-added-successfully")
        );
        await this.getData();
      }
      this.reserForm();
    },
    reserForm() {
      this.data = {
        college_id: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      colleges: "colleges",
      corporationsLoading: "corporationsLoading",
    }),
  },
};
</script>
